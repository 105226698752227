import React from "react";

import "../../assets/styles/pages/terms/terms.scss";

import Termsbreadcrumbpages from  "../raceinfo/termsbreadcrumb";

const Terms = () => {
  return (
    <>
      <section className="terms inner-pages">
      <Termsbreadcrumbpages />
        <div className=" container">
          <div className="row py-4">
            <div className="col-md-12">
              <h4 className="title-heading">TERMS & CONDITIONS</h4>
            </div>
          </div>


          <div className="container" style={{marginBottom: '100px'}}>

            <div className="">
            <ul>
              <li>
                Please choose the event category carefully, confirmed
                registrations are non-refundable, non- transferable and cannot
                be modified. Provide us with a secure email ID/mobile no that
                you can access regularly since this will be our primary means of
                communication during the run-up to the event.
              </li>
              <li>
                Users of email services that offer filtering/blocking of
                messages from unknown email address should add this email id{" "}
                <a style={{color: '#0DA6DE'}}
                  className=""
                  href="mailto:greenheartfulnessrun@heartfulness.org"
                >
                  greenheartfulnessrun@heartfulness.org
                </a>{" "}
                to their address list.
              </li>
              <li>
                We will be sending regular updates to the Mobile number you have
                provided in the registration form this should be not treated as
                spam and you shall not take any action against our bulk SMS
                service provider.
              </li>
              <li>
                Any notice sent to the email address registered with the
                organisers shall be deemed as received by the runners.
              </li>
              <li>
                Please fill out only those fields that are necessary for mailing
                purposes. Do not provide redundant data in multiple fields
                (i.e., do not list the same data for city, province and
                country), as this will only complicate our ability to contact
                you, if necessary.
              </li>
              <li>
                You are aware that running long distance running is an extreme
                sport and can be strenuous for the body and health. You take
                full responsibility for participating in the Green Heartfulness Run
                event and do not hold the organizing committee or any of its
                members or entities responsible for any injury or accident.
              </li>
              <li>
                You shall consult your physician and undergo a complete medical
                examination to assess your suitability to participate in the
                event.
              </li>
              <li>
                You also assume all risks associated with participating in this
                event including, but not limited to, falls, contact with other
                participants, the effects of the weather, including high heat or
                humidity, traffic and the condition of the road, arson or
                terrorist threats and all other risks associated with a public
                event.{" "}
              </li>
              <li>
                You agree that Heartfulness Institute shall not be liable for
                any loss, damage, illness or injury that might occur as a result
                of your participation in the event.
              </li>
              <li>
                You agree to abide by the instructions provided by the
                organizers from time to time in the best interest of your health
                and event safety.
              </li>
              <li>
                You also agree to stop running if instructed by the Race
                director or the medical staff or by the aid station volunteers.
              </li>
              <li>
                You confirm that your name and media recordings taken during
                your participation may be used to publicize the event.
              </li>
              <li>
                You may acknowledge and agree that your personal information can
                be stored and used by Heartfulness Institute or any other
                company in connection with the organisation, promotion and
                administration of the event and for the compilation of
                statistical information.
              </li>
              <li>
                You may acknowledge and agree that your personal information can
                be stored and used by Heartfulness Institute or any other
                company in connection with the organization, promotion and
                administration of the event and for the compilation of
                statistical information. You confirm that, in the event of
                adverse weather conditions, major incidents or threats on the
                day, any of the force majeure restriction by authority, the
                organizers reserve the right to stop/cancel/postpone the event.
                You understand that confirmed registrations and merchandise
                orders are non-refundable, nontransferable and cannot be
                modified. The organizers reserve the right to reject any
                application without providing reasons. Any amount collected from
                rejected applications alone will be refunded in full (excluding
                bank charges wherever applicable){" "}
              </li>
              <li>
                Runners are recommended to stay off the course beyond the
                cut-off time considering the safety and traffic regulations.
              </li>
              <li>
                For any reason you cannot turn up on race day, no refund of any
                form will be given.
              </li>
              <li>
                If this registration is being made on behalf of a minor, I
                confirm that I am the parent / guardian of the child and that
                he/she has my permission to take part in the event. I further
                concur that all the above rules shall apply to him/her as if he
                were a major.
              </li>
              <li>
                Participants must personally appear for collecting the running
                Bibs. Bibs can be distributed in-absentee with the written
                consent and id proof of the registered runners.
              </li>
              <li>
                Each participant must agree to the disclaimer and terms &amp;
                conditions before participating in the event{" "}
              </li>
              <li>
                Half Marathon registrants must be capable of completing the full
                distance, start line to finish
                <br />
                line, within 3.15 hours.
              </li>
              <li>
                5K registrants must be capable of completing the full distance,
                start line to finish line, within 1 Hour.
              </li>
              <li>
                The 10k registrants must be capable to complete the distance
                within 90 mins.
              </li>
              <li>
                Participant race number bibs are specific personal identifiers
                and may not be exchanged with or transferred/ sold to any other
                person. Transfers between runners can only be done through the
                official website within the deadline of transfer displayed on
                the website. Sale and/or transfer of race entry/bibs are
                strictly prohibited and will result in the disqualification
                and/or banning of any individuals involved from future events.
              </li>
            
            </ul>

            <h3><b> &bull; Minimum age requirements…  </b> </h3>           
            <div style={{marginLeft: '30px'}}>
     
            <li style={{listStyleType:"none"}}>
              (a) To register for the 21 Km ___ you must be 18 years of age or
              older on the event day.
            </li>
            <li style={{listStyleType:"none"}}>
              (b) To register for the 10 Km ___ you must be 15 years of age or
              older on the event day.
            </li>
            <li style={{listStyleType:"none"}}>
              (c) To register for the 5 Km ____ you must be 8 years of age or
              older on the event day.
            </li>
            <li style={{listStyleType:"none"}} className="mb-2">
              (c) To register for the 2 Km ____ for minors parents must give a
              consent for their participation.
            </li>  
            </div>
           
 
            </div>
            <h3>
              <b> &bull; Amendments / Reservations / Acknowledgements</b>
            </h3>
            <ul style={{ listStyle: "decimal" }}>
              <li>
                Event officials reserve the right, to modify, supplement or
                waive all or part of the event rules.
              </li>
              <li>
                Participants shall be bound by any modification or supplement of
                the event rules published prior to the event.
              </li>
              <li>
                Failure to follow these event rules, as may be amended, will
                result in immediate disqualification, loss of prize money and
                removal from official results.
              </li>
              <li>
                COVID-19 – Participants also acknowledge the contagious nature
                of COVID-19 and assume the risk they may be exposed to or
                infected by COVID-19 by participating in the Event. Event
                cancellation – In case the event is cancelled due to
                circumstances that are beyond the control of the organisers, all
                confirmed registrations for the year will automatically be
                carried forward to next year in same race category. There is no
                refund of fee in case of event cancellation except for serving
                Indian Defence Personnel.
              </li>
            </ul>
            <h3>
              <b> &bull; Timing Chips:</b>
            </h3>
            <ul>
              <li>
              Green Heartfulness Run will use a timing chip for the timed races
              </li>
              <li>
                Timing chips must be attached to your BIB to score the race as
                per instructions provided
              </li>
              <li>
                This timing chip must be worn on the race day, attached properly
                to the BIB
              </li>
              <li>
                The chip must be attached from Start to Finish in order to be
                recognised as completing the race and receiving an official
                finish time
              </li>
              <li>
                The timing chip identifies your bib number and should not be
                worn by any other participant
              </li>
            </ul>
            <h3>
              <b> &bull; Result Timing:</b>
            </h3>
            <ul style={{ listStyle: "decimal" }}>
              <li>
                All participants are assigned a disposable chip timing device.
                Fitting instructions will be in the race guide. You do not need
                to return your chip if you are unable to run.
              </li>
              <li>
                Clock time (gun time) is the official time for the prize giving.
                The official times shall be recorded from the start of the race
                by the starting gun to the point where the participant crosses
                the finish line. Times shall be rounded to the next highest
                second.
              </li>
              <li>
                Course record (CR) or world record (WR) time if broken by more
                than one individual is awarded solely to the overall male/female
                winner.
              </li>
              <li>
                Official times for mass participants will be the electronic
                timing device time (chip time) from when the participant crosses
                the start line to the point where the participant crosses the
                finish line.
              </li>
              <li>
                Participants must wear their assigned timing device continuously
                from start line to finish line, to receive an accurate finish
                time.
              </li>
              <li>
                Participants found to be wearing two or more timing devices
                during the race will be disqualified and their results will not
                be recorded.
              </li>
              <li>
                Participants with missing timing device times at official
                checkpoints will be reviewed and may be disqualified.{" "}
              </li>
              <li>
                Participants must begin the event during the official recognized
                starting time in order to register an official course completion
                time.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
export default Terms;
