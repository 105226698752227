import React from "react";

import Terms from "../components/sharedComponents/terms";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

// markup
const termspage = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Terms & Condition" />
      <Layout isSticky>
        <Terms />
      </Layout>
    </div>
  );
};

export default termspage;
